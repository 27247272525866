import React from "react";
import * as PrestAPI from "../../service/api/PrestAPI";
import toast, { Toaster } from "react-hot-toast";
import InputField from "../../components/InputField/InputField";
import InputMask from "react-input-mask";
import {
  formatCNPJ,
  formatIE,
  formatPhone,
  formatTime,
} from "../../utils/formatters";
import { validateImage, validateFields } from "../../utils/validators";

const tipoVendaPlataforma = [
  "anotejá subdominio",
  "anotejá multiloja subdominio",
  "anotejá dominio cliente",
  "anotejá multiloja dominio cliente",
  "vempraka",
];
const tipoExibicaoProduto = [
  "desativar carrinho e modal o botão adicionar",
  "exibir preço e habilitar carrinho somente cliente logado",
  "não exibir preço, e habilitar carrinho somente cliente logado",
];

class FormEstabelecimento extends React.Component {
  state = {
    estabelecimento: {
      razao_social: "",
      nome_fantasia: "",
      cnpj: "",
      ie: "",
      segmento: "",
      email: "",
      bairro: "",
      logradouro: "",
      numero: "",
      municipio: "",
      uf: "",
      tempo_minimo_entrega: 0,
      tipo_venda_plataforma: 0,
      permite_pagamento_online: 0,
      permite_uso_vempraka: 0,
      exibe_preco_apenas_cliente_logado: 0,
      habilita_cadastro_proprio_cliente: 0,
      desabilita_todos_tipo_venda: 0,
      habilita_marketplace_vempraka: 0,
      status: 0,
      imagem: null,
      complemento: "",
      horario: {
        horario_Domingo: "08:00|20:00",
        horario_Segunda: "08:00|20:00",
        horario_Terca: "08:00|20:00",
        horario_Quarta: "08:00|20:00",
        horario_Quinta: "08:00|20:00",
        horario_Sexta: "08:00|20:00",
        horario_Sabado: "08:00|20:00",
      },
      tipo_venda: {
        todos: 0,
        delivery: 0,
        retirada: 0,
        encomenda: 0,
        consumir: 1,
        cardapio: 0,
      },
    },
    imageUrl: null,
    categorias: [],
    isLoadingImage: false,
    isLoadingEstabelecimento: false,
    isLoadingCategorias: false,
  };

  componentDidMount() {
    this.isComponentMounted = true;
    this.getCategorias();
    if (this.props.estabelecimentoId) {
      this.getEstabelecimento(this.props.estabelecimentoId);
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.estabelecimentoId !== this.props.estabelecimentoId) {
      this.getEstabelecimento(this.props.estabelecimentoId);
    }
  }

  componentWillUnmount() {
    this.isComponentMounted = false;
  }
  getEstabelecimento = async (id) => {
    this.setState({ isLoadingEstabelecimento: true });
    try {
      const res = await PrestAPI.get("Estabelecimento", `estab=${id}`);
      if (!res) {
        throw new Error("Estabelecimento não encontrado");
      }

      const mappedTipoVenda = {
        todos: 0,
        delivery: 0,
        retirada: 0,
        encomenda: res.permite_agendamento || 0,
        consumir: 0,
        cardapio: res.habilita_modo_cardapio || 0,
      };

      this.setState({
        estabelecimento: {
          ...res,
          tipo_venda: mappedTipoVenda,
        },
        isLoadingEstabelecimento: false,
      });
    } catch (error) {
      console.error("Erro ao buscar o estabelecimento:", error);
      toast.error(
        "Erro ao buscar o estabelecimento. Por favor, tente novamente."
      );
      this.setState({ isLoadingEstabelecimento: false });
    }
  };

  getCategorias = async () => {
    this.setState({ isLoadingCategorias: true });
    try {
      const result = await PrestAPI.get("Categorias");
      this.setState({
        categorias: result.categorias,
        isLoadingCategorias: false,
      });
    } catch (error) {
      console.log("Erro ao buscar categorias");
      this.setState({ isLoadingCategorias: false });
    }
  };

  addEstabelecimento = async (estabelecimento) => {
    try {
      const result = await PrestAPI.add("Estabelecimento", estabelecimento);
      if (result.status === 200) {
        toast.success("Estabelecimento Salvo com sucesso");
      } else {
        toast.error(result.error);
      }
    } catch (error) {
      toast.error(error.message || "Erro ao adicionar o estabelecimento");
    }
  };

  uploadImage = async (image) => {
    this.setState({ isLoadingImage: true });
    try {
      if (!validateImage(image)) {
        return null;
      }

      const formData = new FormData();
      formData.append("file", image);

      const result = await PrestAPI.add("upload-image", formData);
      if (result.ok) {
        const data = await result.json();
        const imageUrl = data.imageUrl;
        this.setState({ imageUrl, isLoadingImage: false });
        return imageUrl;
      } else {
        const errorResponse = await result.json();
        console.log(errorResponse.error || "Erro ao carregar imagem");
        this.setState({ isLoadingImage: false });
        return null;
      }
    } catch (error) {
      console.log(error.message || "Erro ao adicionar imagem");
      this.setState({ isLoadingImage: false });
      return null;
    }
  };
  handleTimeChange = (event) => {
    const { name, value } = event.target;
    this.setState((prevState) => {
      const estabelecimento = { ...prevState.estabelecimento };
      estabelecimento.horario[name] = formatTime(value);
      return { estabelecimento };
    });
  };

  handleFileChange = (event) => {
    const { files } = event.target;
    const image = files[0];
    if (validateImage(image)) {
      this.setState((prevState) => ({
        estabelecimento: {
          ...prevState.estabelecimento,
          imagem: image,
        },
      }));
    }
  };

  handleChange = (event) => {
    const { name, value, type, checked } = event.target;

    this.setState((prevState) => {
      const estabelecimento = { ...prevState.estabelecimento };

      if (type === "checkbox") {
        estabelecimento.tipo_venda[name] = checked ? 1 : 0;
      } else {
        estabelecimento[name] = value;
      }

      return { estabelecimento };
    });
  };

  handleSubmit = async (event) => {
    event.preventDefault();
    const { estabelecimento } = this.state;

    const errors = validateFields(estabelecimento);
    if (Object.keys(errors).length > 0) {
      Object.values(errors).forEach((error) => toast.error(error));
      return;
    }

    const preparedEstabelecimento = await this.prepareEstabelecimentoData();
    if (preparedEstabelecimento) {
      this.addEstabelecimento(preparedEstabelecimento);
    }
  };

  prepareEstabelecimentoData = async () => {
    const { estabelecimento } = this.state;
    if (estabelecimento.imagem) {
      const imageUrl = await this.uploadImage(estabelecimento.imagem);
      if (imageUrl) {
        estabelecimento.imagem = imageUrl;
      } else {
        return null;
      }
    }

    return {
      ...estabelecimento,
      contato_telefone: estabelecimento.telefone_contato,
      contato_email: estabelecimento.contato_email,
      senha: "123",
    };
  };

  render() {
    const {
      categorias,
      isLoadingEstabelecimento,
      isLoadingCategorias,
      estabelecimento,
    } = this.state;

    if (isLoadingEstabelecimento || isLoadingCategorias) {
      return (
        <div className="loader" id="loader">
          <span></span>
          <span></span>
          <span></span>
        </div>
      );
    }

    return (
      <React.Fragment>
        <form className="form-horizontal row" onSubmit={this.handleSubmit}>
          <InputField
            className="col-md-6"
            label="Razão social"
            type="text"
            name="razao_social"
            placeholder="Digite a razão social"
            value={estabelecimento.razao_social}
            onChange={this.handleChange}
            required
          />
          <InputField
            className="col-md-6"
            label="Nome fantasia"
            type="text"
            name="nome_fantasia"
            placeholder="Digite o nome fantasia"
            value={estabelecimento.nome_fantasia}
            onChange={this.handleChange}
            required
          />
          <InputField
            className="col-md-4"
            label="CNPJ"
            type="text"
            name="cnpj"
            placeholder="Digite o CNPJ"
            value={formatCNPJ(estabelecimento.cnpj)}
            onChange={this.handleChange}
            required
          />
          <InputField
            className="col-md-4"
            label="Inscrição Estadual"
            type="text"
            name="ie"
            placeholder="Digite a inscrição estadual"
            value={formatIE(estabelecimento.ie)}
            onChange={this.handleChange}
          />
          <div className="col-md-4">
            <label htmlFor="segmento">Segmento</label>
            <select
              className="form-select"
              id="segmento"
              name="segmento"
              value={estabelecimento.segmento}
              onChange={this.handleChange}
              required
            >
              <option value="">Selecione o segmento</option>
              {categorias.map((categoria, index) => (
                <option key={index} value={categoria.nome}>
                  {categoria.nome}
                </option>
              ))}
            </select>
          </div>
          <InputField
            className="col-md-4"
            label="Email"
            type="text"
            name="email"
            placeholder="Digite o email"
            value={estabelecimento.email}
            onChange={this.handleChange}
            required
          />
          <InputField
            className="col-md-4"
            label="Contato"
            type="text"
            name="contato"
            placeholder="Digite o nome do contato"
            value={estabelecimento.contato}
            onChange={this.handleChange}
          />
          <InputField
            className="col-md-4"
            label="Telefone do contato"
            type="text"
            name="telefone_contato"
            placeholder="(99) 99999-9999"
            value={formatPhone(estabelecimento.telefone_contato)}
            onChange={this.handleChange}
          />
          <InputField
            className="col-md-6"
            label="Bairro"
            type="text"
            name="bairro"
            placeholder="Digite o bairro"
            value={estabelecimento.bairro}
            onChange={this.handleChange}
          />
          <InputField
            className="col-md-6"
            label="Logradouro"
            type="text"
            name="logradouro"
            placeholder="Digite o logradouro"
            value={estabelecimento.logradouro}
            onChange={this.handleChange}
          />
          <InputField
            className="col-md-8"
            label="Complemento"
            type="text"
            name="complemento"
            placeholder="Digite o complemento"
            value={estabelecimento.complemento}
            onChange={this.handleChange}
          />
          <InputField
            className="col-md-4"
            label="CEP"
            type="text"
            name="cep"
            placeholder="Digite o CEP"
            value={estabelecimento.cep}
            onChange={this.handleChange}
          />
          <InputField
            className="col-md-2"
            label="Número"
            type="text"
            name="numero"
            placeholder="Digite o número"
            value={estabelecimento.numero}
            onChange={this.handleChange}
          />
          <InputField
            className="col-md-4"
            label="Cidade"
            type="text"
            name="municipio"
            placeholder="Digite a cidade"
            value={estabelecimento.municipio}
            onChange={this.handleChange}
          />
          <InputField
            className="col-md-2"
            label="UF"
            type="text"
            name="uf"
            placeholder="Digite a UF"
            value={estabelecimento.uf}
            onChange={this.handleChange}
          />
          <div className="col-md-4">
            <label htmlFor="imagem" className="form-label">
              Selecione uma imagem
            </label>
            <input
              className="form-control"
              name="imagem"
              type="file"
              onChange={this.handleFileChange}
            />
          </div>

          <div className="row border-bottom border-top py-2 my-2 mx-1">
            <div
              className="my-3 p-3 border border-2 rounded position-relative w-100"
              style={{ maxWidth: "300px" }}
            >
              <span
                className="position-absolute bg-white px-2"
                style={{ top: "-10px", left: "20px" }}
              >
                Tipos de vendas
              </span>
              <div className="row">
                {[
                  "todos",
                  "delivery",
                  "retirada",
                  "encomenda",
                  "consumir",
                  "cardapio",
                ].map((tipo, index) => (
                  <div className="col-6" key={index}>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        name={tipo}
                        id={`checkbox-${tipo}`}
                        checked={estabelecimento.tipo_venda[tipo] === 1}
                        onChange={this.handleChange}
                        aria-label={`Tipo de venda: ${tipo}`}
                      />
                      <label
                        htmlFor={`checkbox-${tipo}`}
                        className="form-check-label"
                      >
                        {tipo.charAt(0).toUpperCase() + tipo.slice(1)}
                      </label>
                    </div>
                  </div>
                ))}
              </div>
            </div>

            <div className="col">
              <label htmlFor="exibir_produtos" className="form-label">
                Tipos de Exibição dos produtos
              </label>
              <select
                className="form-select"
                name="exibir_produtos"
                value={estabelecimento.exibir_produtos}
                onChange={this.handleChange}
              >
                {tipoExibicaoProduto.map((option, index) => (
                  <option key={index} value={option}>
                    {option}
                  </option>
                ))}
              </select>
            </div>
            <div className="col">
              <label htmlFor="tipo_venda_plataforma" className="form-label">
                Tipos de venda plataforma
              </label>
              <select
                className="form-select"
                name="tipo_venda_plataforma"
                value={estabelecimento.tipo_venda_plataforma}
                onChange={this.handleChange}
              >
                {tipoVendaPlataforma.map((option, index) => (
                  <option key={index} value={option}>
                    {option}
                  </option>
                ))}
              </select>
            </div>
          </div>

          <div className="w-100 py-2 border-bottom border-light">
            <h5 className="text-center fs-5">Horário de atendimento</h5>

            <div className="row">
              {Object.keys(estabelecimento.horario).map((key) => (
                <div className="col-md-2" key={key}>
                  <label htmlFor={key} className="form-label">
                    {key.replace("horario_", "").replace("_", " ")}
                  </label>
                  <InputMask
                    mask="99:99|99:99"
                    className="form-control"
                    type="text"
                    placeholder="08:00|20:00"
                    name={key}
                    value={formatTime(estabelecimento.horario[key])}
                    onChange={this.handleTimeChange}
                    required
                  />
                </div>
              ))}
            </div>
          </div>
          {estabelecimento.tipo_venda.encomenda === 1 && (
            <InputField
              className="col-md-2"
              label="Tempo mínimo para entrega"
              type="text"
              name="tempo_minimo_entrega"
              placeholder="Digite o tempo mínimo para entrega"
              value={estabelecimento.tempo_minimo_entrega}
              onChange={this.handleChange}
            />
          )}

          <div className="col-md-4">
            <label>Empresa Ativa:</label>
            <div className="row input-group ">
              <label htmlFor="empresa-ativa-sim" className="radio-label">
                <input
                  type="radio"
                  name="status"
                  id="empresa-ativa-sim"
                  value={1}
                  aria-label="Ativar empresa"
                  checked={estabelecimento.status === 1}
                  onChange={() =>
                    this.setState((prevState) => ({
                      estabelecimento: {
                        ...prevState.estabelecimento,
                        status: 1,
                      },
                    }))
                  }
                />
                Sim
              </label>
              <label htmlFor="empresa-ativa-nao" className="radio-label">
                <input
                  type="radio"
                  name="status"
                  value={0}
                  id="empresa-ativa-nao"
                  aria-label="Desativar empresa"
                  checked={estabelecimento.status === 0}
                  onChange={() =>
                    this.setState((prevState) => ({
                      estabelecimento: {
                        ...prevState.estabelecimento,
                        status: 0,
                      },
                    }))
                  }
                />
                Não
              </label>
            </div>
          </div>

          <div className="row my-2">
            <div className="col-lg-3">
              <button
                type="submit"
                className="btn btn-vk w-100"
                aria-label={
                  this.props.isEditing
                    ? "Salvar Alterações"
                    : "Cadastrar Empresa"
                }
              >
                {this.props.isEditing
                  ? "Salvar Alterações"
                  : "Cadastrar Empresa"}
              </button>
            </div>
            <div className="col-lg-2">
              <button
                type="button"
                className="btn btn-outline-secondary w-100"
                onClick={this.props.onCancel}
                aria-label="Cancelar e retornar"
              >
                Cancelar
              </button>
            </div>
          </div>
        </form>
        <Toaster />
      </React.Fragment>
    );
  }
}

export default FormEstabelecimento;
