import React, { Component } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import "./Style.css";
import { Accordion, Card } from "react-bootstrap";
import * as PrestAPI from "../../service/api/PrestAPI";

class Produto extends Component {
  state = {
    categorias: [],
    produtos: [],
    statusFiltro: "Ativo",
    loading: true,
    activeKeys: [],
  };

  async componentDidMount() {
    const { estabelecimento } = this.props;

    try {
      const respCategorias = await PrestAPI.get(
        "ProdutoGrupo",
        `estab=${estabelecimento.id_estabelecimento}&modo=retaguarda`
      );

      const activeKeys = respCategorias.categorias.map(
        (categoria) => categoria.id_grupo
      );
      this.setState({ categorias: respCategorias.categorias, activeKeys });

      const respProdutos = await PrestAPI.get(
        "Produto",
        `estab=${estabelecimento.id_estabelecimento}&modo=retaguarda`
      );

      this.setState({ produtos: respProdutos.produtos, loading: false });
    } catch (error) {
      this.setState({ loading: false });
    }
  }

  handleAccordionToggle = (id_grupo) => {
    this.setState((prevState) => {
      const { activeKeys } = prevState;
      const isActive = activeKeys.includes(id_grupo);

      return {
        activeKeys: isActive
          ? activeKeys.filter((key) => key !== id_grupo)
          : [...activeKeys, id_grupo],
      };
    });
  };

  handleNewProductClick = (categoria) => (e) => {
    this.props.setSelectedCategoria(categoria.descricao);
    this.props.redirectAdd();
    e.stopPropagation();
  };

  render() {
    const { editProduto, editGrupo, estabelecimento } = this.props;
    const { categorias, produtos, statusFiltro, loading, activeKeys } =
      this.state;

    return (
      <div className="container">
        <div className="row mt-4 mb-3 px-1 justify-content-between">
          <div className="col-lg-6">
            <Link to="/produto_grupo-add" className="btn btn-vk">
              Adicionar categoria
            </Link>
          </div>
          <div className="col-lg-4 justify-content-end">
            <div className="row mt-auto align-items-center">
              <label className="col-sm-5 col-form-label text-center">
                Status do Produto:
              </label>
              <div className="col-lg-7">
                <select
                  className="form-select"
                  id="statusFiltro"
                  onChange={(e) =>
                    this.setState({ statusFiltro: e.target.value })
                  }
                >
                  <option>Ativo</option>
                  <option>Inativo</option>
                  <option>Todos</option>
                </select>
              </div>
            </div>
          </div>
        </div>

        <Accordion activeKey={activeKeys}>
          {categorias &&
            categorias.map((categoria) => (
              <Card key={categoria.id_grupo} className="m-4">
                <Card.Header
                  style={{ cursor: "pointer" }}
                  onClick={() => this.handleAccordionToggle(categoria.id_grupo)}
                >
                  <div className="row my-3">
                    <div className="col col-lg-6 text-center">
                      <h3>{categoria.descricao}</h3>
                    </div>
                    <div className="col col-lg-6 row justify-content-end">
                      <div className="col col-md-auto my-auto">
                        <button
                          className="btn btn-vk btn-block mt-2"
                          onClick={this.handleNewProductClick(categoria)}
                        >
                          Novo produto
                        </button>
                      </div>
                      <div className="col col-md-auto my-auto">
                        <button
                          className="btn btn-vk mt-2"
                          onClick={() => editGrupo(categoria)}
                          style={{ marginTop: "10px" }}
                        >
                          Editar categoria
                        </button>
                      </div>
                    </div>
                  </div>
                </Card.Header>
                <Accordion.Collapse eventKey={categoria.id_grupo}>
                  <Card.Body>
                    {!loading ? (
                      produtos &&
                      produtos
                        .filter((p) => {
                          if (statusFiltro === "Ativo") {
                            return (
                              p.id_grupo === categoria.id_grupo &&
                              p.status_produto === 1
                            );
                          } else if (statusFiltro === "Inativo") {
                            return (
                              p.id_grupo === categoria.id_grupo &&
                              p.status_produto === 0
                            );
                          } else {
                            return p.id_grupo === categoria.id_grupo;
                          }
                        })
                        .map((produto) => (
                          <div key={produto.id_produto} style={prodStyle}>
                            <div className="row tabProduto">
                              <div
                                className="col-sm-2"
                                style={{ textAlign: "center" }}
                              >
                                <img
                                  src={
                                    produto.imagem !== ""
                                      ? produto.imagem
                                      : estabelecimento.imagem
                                  }
                                  alt="imgProd"
                                  style={{
                                    maxWidth: "50%",
                                    height: "50px",
                                    borderRadius: "20%",
                                  }}
                                />
                              </div>
                              <div
                                className="col-sm-4"
                                style={{ fontSize: "17px" }}
                              >
                                {produto.nome}
                              </div>
                              <div className="col-sm-2">
                                <h4>
                                  {new Intl.NumberFormat("pt-BR", {
                                    style: "currency",
                                    currency: "BRL",
                                  }).format(produto.vr_unitario)}
                                </h4>
                              </div>
                              <div className="col-sm-2">
                                <b style={{ color: "orange" }}></b>
                              </div>
                              <div
                                className="col-sm-2"
                                style={{ textAlign: "center" }}
                              >
                                <button
                                  className="btn btn-vk-edit btn-md"
                                  onClick={() => editProduto(produto)}
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16"
                                    height="16"
                                    fill="currentColor"
                                    className="bi bi-pencil-fill"
                                    viewBox="0 0 16 16"
                                  >
                                    <path d="M12.854.146a.5.5 0 0 0-.707 0L10.5 1.793 14.207 5.5l1.647-1.646a.5.5 0 0 0 0-.708l-3-3zm.646 6.061L9.793 2.5 3.293 9H3.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.207l6.5-6.5zm-7.468 7.468A.5.5 0 0 1 6 13.5V13h-.5a.5.5 0 0 1-.5-.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.5-.5V10h-.5a.499.499 0 0 1-.175-.032l-.179.178a.5.5 0 0 0-.11.168l-2 5a.5.5 0 0 0 .65.65l5-2a.5.5 0 0 0 .168-.11l.178-.178z" />
                                  </svg>
                                </button>
                              </div>
                            </div>
                          </div>
                        ))
                    ) : (
                      <div className="loader" id="loader">
                        <span></span>
                        <span></span>
                        <span></span>
                      </div>
                    )}
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
            ))}
        </Accordion>
      </div>
    );
  }
}

export default Produto;

const prodStyle = {
  padding: 5,
};
