import React, { Component } from "react";
import PropTypes from "prop-types";
import * as PrestAPI from "../../service/api/PrestAPI";
import "./Pedidos.css";
import { formatCurrencyBRL } from "../../utils/formatters";
import { X, Check2 } from "react-bootstrap-icons";

const statusMap = {
  PENDENTE: "CONFIRMADO",
  CONFIRMADO: "ENVIADO",
  ENVIADO: "ENTREGUE",
};

class Pedidos extends Component {
  static propTypes = {
    estabelecimento: PropTypes.object.isRequired,
    pedidos: PropTypes.array.isRequired,
    setConfirmaPedido: PropTypes.func,
    unsetConfirmaPedido: PropTypes.func,
  };

  state = {
    pedido: {},
    documentoHide: "",
    itens: [],
    modoPedido: "ABERTO",
    subtotal: 0,
  };

  setPedido = (pedido) => {
    PrestAPI.get("Pedidos", `id_pedido=${pedido.id_pedido}`).then((value) => {
      let documento = pedido.documento;

      if (documento !== null && documento !== undefined && documento !== "") {
        if (documento.length === 14) {
          documento = documento.substr(0, 5);
          documento = documento += "**.***-**";
          this.setState({ documentoHide: documento });
        } else {
          documento = documento.substr(0, 4);
          documento = documento += "**.***/****-**";
          this.setState({ documentoHide: documento });
        }
      } else {
        this.setState({ documentoHide: "" });
      }

      this.setState({ pedido });
      this.setState({ itens: value });
      this.calSubtotal();
    });
  };
  calSubtotal = () => {
    const { itens } = this.state;

    const subtotal = itens.reduce((soma, item) => {
      const totalSubItens = item.vr_total_sub_itens || 0;
      const preco = (item.vr_unitario + totalSubItens) * item.quantidade;
      return soma + preco;
    }, 0);

    this.setState({ subtotal });
  };

  handleCloseModal = () => {
    this.setState({ showModal: false });
  };

  updateStatusPedido = async (pedido, statusPedido) => {
    try {
      pedido.status_pedido = statusPedido;
      const data = await PrestAPI.update(
        "Pedido",
        `?id_pedido=${pedido.id_pedido}`,
        pedido
      );
      if (data.rows_affected !== 0) {
        this.setState({ pedido });
      }
      const value = await PrestAPI.get(
        "Pedidos",
        `estab=${this.props.estabelecimento.id_estabelecimento}`
      );
      const pendingOrders = value.pedidos.filter(
        (pedido) => pedido.status_pedido === "PENDENTE"
      );

      if (pendingOrders.length) {
        this.props.setConfirmaPedido();
      } else {
        this.props.unsetConfirmaPedido();
      }
      this.setState({ pedidos: value.pedidos });
    } catch (error) {
      alert(error);
    }
  };

  renderStatus = (statusPedido, isTitle = false) => {
    const statusConfig = {
      CONFIRMADO: { border: "1px solid DeepSkyBlue", text: "Confirmado" },
      ENVIADO: { border: "1px solid gray", text: "Enviado" },
      ENTREGUE: { border: "1px solid green", text: "Entregue" },
      CANCELADO: { border: "1px solid red", text: "Cancelado" },
      default: { border: "1px solid orange", text: "Pendente" },
    };

    const { border, text } = statusConfig[statusPedido] || statusConfig.default;

    return isTitle ? (
      <h4
        style={{
          border,
          borderRadius: "5px",
          textAlign: "center",
          margin: "20px, 0px, 10px",
        }}
      >
        {text}
      </h4>
    ) : (
      <div
        className="col-12"
        style={{
          border,
          borderRadius: "5px",
          textAlign: "center",
        }}
      >
        {text}
      </div>
    );
  };

  verificaStatus = (pedido) => {
    const { modoPedido } = this.state;
    const isAberto = modoPedido === "ABERTO";

    return isAberto
      ? !["CANCELADO", "ENTREGUE"].includes(pedido.status_pedido)
      : ["CANCELADO", "ENTREGUE"].includes(pedido.status_pedido);
  };

  render() {
    const { pedidos } = this.props;
    const { pedido, documentoHide, itens, subtotal, modoPedido } = this.state;

    return (
      <div className="container-fluid">
        <div className="row">
          <div
            className="card col-sm-4 col-12"
            style={{
              maxHeight: "85vh",
              minHeight: "85vh",
              overflow: "auto",
              borderRadius: "3px 0px 0px 3px",
            }}
          >
            <div className="row mb-4 card-header">
              <div className="col-6">
                <button
                  className="btn w-100"
                  style={{
                    outline: "none",
                    borderRadius: "2px 0px 0px 0px",
                    borderBottom: `${
                      modoPedido === "ABERTO" ? "solid 3px #ff5722" : ""
                    }`,
                    borderRight: "none",
                  }}
                  onClick={() => this.setState({ modoPedido: "ABERTO" })}
                >
                  {modoPedido === "ABERTO" ? (
                    <b style={{ color: "#ff5722" }}>Aberto</b>
                  ) : (
                    <>Aberto</>
                  )}
                </button>
              </div>
              <div className="col-6">
                <button
                  className="btn w-100"
                  style={{
                    outline: "none",
                    borderRadius: "0px 0px 0px 2px",
                    borderBottom: `${
                      modoPedido !== "ABERTO" ? "solid 3px #ff5722" : ""
                    }`,
                    borderLeft: "none",
                  }}
                  onClick={() => this.setState({ modoPedido: "FINALIZADO" })}
                >
                  {modoPedido !== "ABERTO" ? (
                    <b style={{ color: "#ff5722" }}>Finalizado</b>
                  ) : (
                    <>Finalizado</>
                  )}
                </button>
              </div>
            </div>

            {pedidos &&
              pedidos
                .filter((pedido) => this.verificaStatus(pedido))
                .map((pedido) => (
                  <div
                    className="card clickable pedido mt-3"
                    key={pedido.id_pedido}
                    onClick={() => this.setPedido(pedido)}
                    style={{
                      WebkitUserSelect: "none",
                      MozUserSelect: "none",
                      MsUserSelect: "none",
                      userSelect: "none",
                      backgroundColor: `${
                        pedido.status_pedido === "PENDENTE"
                          ? "#fcee9f"
                          : pedido.status_pedido === "ENTREGUE"
                          ? "#abfc8b"
                          : pedido.status_pedido === "CANCELADO"
                          ? "#fc9797"
                          : ""
                      }`,
                    }}
                  >
                    <div className="card-body">
                      <div className="row">
                        <div className="col-2">{pedido.id_pedido}</div>
                        <div className="col-5 p-0">{pedido.nome_cliente}</div>
                        <div className="col-5">
                          <div className="col-12">
                            {this.renderStatus(pedido.status_pedido)}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
          </div>
          {pedido.id_pedido ? (
            <div
              className="card col-sm-8 col-12"
              style={{
                maxHeight: "85vh",
                minHeight: "85vh",
                overflow: "auto",
                padding: "30px 80px 30px 80px",
                borderRadius: "0px 3px 3px 0px",
                borderLeft: "none",
              }}
            >
              <div className="row">
                <div
                  className="col-sm-3"
                  style={{
                    WebkitUserSelect: "none",
                    MozUserSelect: "none",
                    MsUserSelect: "none",
                    userSelect: "none",
                  }}
                >
                  {this.renderStatus(pedido.status_pedido)}
                </div>
                <div className="col-6" style={{ textAlign: "center" }}>
                  <h3>
                    <b>{`Pedido ${pedido.id_pedido}`}</b>
                  </h3>
                  {pedido.tipo_pedido === "RETIRADA" ? (
                    <h4 style={{ color: "red" }}>Pedido para retirada</h4>
                  ) : null}
                </div>
                <div className="col-3">
                  <span style={{ fontSize: "15px" }}>
                    {new Date(pedido.data_hora).toLocaleString("pt-BR", {
                      timeZone: "GMT",
                    })}
                  </span>
                </div>
              </div>
              {pedido.id_pedido && (
                <div className="row">
                  {pedido.data_hora_agendamento && (
                    <div className="col-md-12" style={{ textAlign: "left" }}>
                      <b>Data de encomenda: </b>
                      <div style={{ fontSize: "15px" }}>
                        {new Date(pedido.data_hora_agendamento).toLocaleString(
                          "pt-BR",
                          {
                            timeZone: "GMT",
                          }
                        )}
                      </div>
                    </div>
                  )}
                  <div
                    className={pedido.telefone ? "col-sm-9" : "col-sm-12"}
                    style={{ textAlign: "left" }}
                  >
                    <h5 className="m-0">
                      <b>Cliente:</b>
                      <div style={{ fontSize: "16px" }}>
                        {` ${pedido.nome_cliente}`}
                      </div>
                    </h5>
                  </div>
                  {pedido.telefone && (
                    <div className="col-sm-3 text-lef">
                      <b>Telefone:</b>
                      <div style={{ fontSize: "16px" }}>{pedido.telefone}</div>
                    </div>
                  )}
                  <div
                    className={pedido.documento ? "col-sm-9" : "col-sm-12"}
                    style={{ textAlign: "left" }}
                  >
                    <h5>
                      <b>Endereço:</b>
                      {(pedido.entrega_logradouro || pedido.entrega_numero) && (
                        <div style={{ fontSize: "16px" }}>
                          {pedido.entrega_logradouro || ""}
                          {pedido.entrega_logradouro && pedido.entrega_numero
                            ? ", "
                            : ""}
                          {pedido.entrega_numero || ""}
                        </div>
                      )}
                      {(pedido.entrega_bairro ||
                        pedido.entrega_municipio ||
                        pedido.entrega_uf) && (
                        <div style={{ fontSize: "16px" }}>
                          {pedido.entrega_bairro || ""}
                          {pedido.entrega_bairro && pedido.entrega_municipio
                            ? " - "
                            : ""}
                          {pedido.entrega_municipio || ""}
                          {(pedido.entrega_bairro ||
                            pedido.entrega_municipio) &&
                          pedido.entrega_uf
                            ? ", "
                            : ""}
                          {pedido.entrega_uf || ""}
                        </div>
                      )}
                      {pedido.entrega_complemento && (
                        <div style={{ fontSize: "16px" }}>
                          {pedido.entrega_complemento}
                        </div>
                      )}
                    </h5>
                  </div>
                  {pedido.documento && (
                    <>
                      <div className="col-sm-3 text-left">
                        <h5 style={{ marginTop: "10px", marginBottom: "0px" }}>
                          <b>Documento:</b>
                          <div style={{ fontSize: "16px" }}>
                            {documentoHide}
                          </div>
                        </h5>
                      </div>
                    </>
                  )}
                  <div className="col-sm-12">
                    <hr
                      style={{ borderTop: "solid 1.5px", color: "lightgray" }}
                    />
                  </div>
                  <div className="container-fluid col-sm-12">
                    {itens.map((item) => (
                      <div className="card" key={item.num_item}>
                        <div className="card-body">
                          <div className="row">
                            <div
                              className="col"
                              style={{ fontSize: "15px" }}
                            >{`${item.quantidade}x ${item.nome_produto}`}</div>
                            <div className="col text-end">
                              {formatCurrencyBRL(item.vr_unitario)}
                            </div>
                            {item.subItens &&
                              item.subItens.map(
                                (complemento) =>
                                  complemento && (
                                    <div
                                      key={complemento.id_complemento}
                                      className="col-12 row"
                                    >
                                      <div
                                        className="col text-body-secondary ps-4"
                                        style={{ fontSize: "12px" }}
                                      >
                                        {` ${complemento.quantidade}x ${complemento.complemento}`}
                                      </div>
                                      <div
                                        className="col text-body-secondary justify-content-end row"
                                        style={{
                                          fontSize: "12px",
                                        }}
                                      >
                                        {formatCurrencyBRL(
                                          complemento.vr_adicional
                                        )}
                                      </div>
                                    </div>
                                  )
                              )}

                            <div
                              className="col-12 text-end"
                              style={{
                                fontWeight: "bold",
                                fontSize: "16px",
                              }}
                            >
                              {item.vr_total_sub_iten
                                ? formatCurrencyBRL(
                                    item.vr_unitario + item.vr_total_sub_itens
                                  )
                                : formatCurrencyBRL(item.vr_total)}
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}

                    <div className="row">
                      <div className="col-md-6 ms-auto">
                        <div className="row justity-content-between">
                          <div className="col" style={{ fontSize: "16px" }}>
                            <b>Subtotal:</b>
                          </div>
                          <div
                            className="col text-end"
                            style={{ fontSize: "16px" }}
                          >
                            <b>{formatCurrencyBRL(subtotal)}</b>
                          </div>
                        </div>
                        <div className="row justity-content-between">
                          <div className="col">
                            <b>Taxa:</b>
                          </div>
                          <div className="col text-end">
                            <b>{formatCurrencyBRL(pedido.entrega_taxa)}</b>
                          </div>
                        </div>
                        <div className="row justity-content-between">
                          <div className="col" style={{ fontSize: "16px" }}>
                            <b>Total:</b>
                          </div>
                          <div
                            className="col text-end"
                            style={{ fontSize: "16px" }}
                          >
                            <b>{formatCurrencyBRL(pedido.vr_pedido)}</b>
                          </div>
                        </div>
                        {pedido.vr_troco !== 0 && (
                          <div className="row justity-content-between">
                            <div
                              className="col"
                              style={{
                                fontSize: "16px",
                              }}
                            >
                              <b>Troco para:</b>
                            </div>
                            <div
                              className="col text-end"
                              style={{ fontSize: "16px" }}
                            >
                              <b>{formatCurrencyBRL(pedido.vr_troco)}</b>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>

                    <div className=" w-100">
                      <div className="col-12">
                        <b>Forma de Pagamento:</b>
                      </div>
                      <div className="col-12">{pedido.forma_pagamento}</div>
                      {pedido.entrega_observacao ? (
                        <>
                          <div
                            className="col-sm-12 text-left"
                            style={{ marginTop: "36px" }}
                          >
                            <h5
                              style={{ marginTop: "10px", marginBottom: "0px" }}
                            >
                              <b>Observações:</b>
                            </h5>
                          </div>
                          <div className="col-sm-12 text-left">
                            <div style={{ fontSize: "16px" }}>
                              {pedido.entrega_observacao}
                            </div>
                          </div>
                        </>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-sm-12">
                    <hr
                      style={{ borderTop: "solid 1.5px", color: "lightgray" }}
                    />
                  </div>
                  <div className="w-100 row g-2">
                    {/* Botão de Cancelar */}
                    {pedido.status_pedido !== "CANCELADO" &&
                      pedido.status_pedido !== "ENTREGUE" &&
                      pedido.status_pedido !== "ENVIADO" && (
                        <div className="col-sm-3">
                          <button
                            className="btn btn-outline-secondary w-100 d-flex align-items-center justify-content-center"
                            onClick={() =>
                              this.updateStatusPedido(pedido, "CANCELADO")
                            }
                          >
                            <X size={20} className="me-2" />
                            <span>Cancelar</span>
                          </button>
                        </div>
                      )}

                    {/* Botão de Confirmar/Despachar/Concluir */}
                    {pedido.status_pedido !== "CANCELADO" &&
                      pedido.status_pedido !== "ENTREGUE" && (
                        <div className="col-sm-3">
                          <button
                            className="btn btn-vk w-100 d-flex align-items-center justify-content-center"
                            onClick={() => {
                              const novoStatus =
                                statusMap[pedido.status_pedido] || "ENTREGUE";
                              this.updateStatusPedido(pedido, novoStatus);
                            }}
                          >
                            <Check2 size={20} className="me-2" />
                            {pedido.status_pedido === "PENDENTE" && (
                              <span>Confirmar</span>
                            )}
                            {pedido.status_pedido === "CONFIRMADO" && (
                              <span>Despachar</span>
                            )}
                            {pedido.status_pedido === "ENVIADO" && (
                              <span>Concluir</span>
                            )}
                          </button>
                        </div>
                      )}
                  </div>
                </div>
              )}
            </div>
          ) : null}
        </div>
      </div>
    );
  }
}

export default Pedidos;
