import React from "react";
import * as PrestAPI from "../../service/api/PrestAPI";
import toast, { Toaster } from "react-hot-toast";
import FormEstabelecimento from "./FormEstabelecimento";
import { Pencil, Trash3 } from "react-bootstrap-icons";

class Empresas extends React.Component {
  state = {
    estabelecimentos: [],
    showCampos: false,
    selectedEstabelecimentoId: null,
    isEditing: false,
    searchQuery: "",
    isLoading: false,
  };
  componentDidMount() {
    this.getAllEstabelecimentos();
  }

  getAllEstabelecimentos = async () => {
    this.setState({ isLoading: true });
    try {
      const res = await PrestAPI.get("Estabelecimento");
      this.setState({
        estabelecimentos: res.estabelecimentos,
        isLoading: false,
      });
    } catch (error) {
      this.setState({ isLoading: false });
      toast.error(error.message || "Erro ao buscar os estabelecimentos");
    }
  };

  toggleForm = (isEditing = false, estabelecimentoId = null) => {
    this.setState({
      showCampos: true,
      isEditing,
      selectedEstabelecimentoId: estabelecimentoId,
    });
  };

  handleCancel = () => {
    this.setState({ showCampos: false });
  };

  handleDelete = async (id) => {};

  handleSearchChange = (e) => {
    this.setState({ searchQuery: e.target.value });
  };

  render() {
    const {
      estabelecimentos,
      showCampos,
      selectedEstabelecimentoId,
      isEditing,
      searchQuery,
      isLoading,
    } = this.state;

    const filteredEstabelecimentos = estabelecimentos.filter(
      (estabelecimento) =>
        estabelecimento.razao_social
          .toLowerCase()
          .includes(searchQuery.toLowerCase())
    );

    return (
      <section className="container-fluid">
        <div className="w-100 py-4">
          {!showCampos && (
            <button
              type="button"
              className="btn btn-vk"
              onClick={() => this.toggleForm(false)}
            >
              Cadastrar Nova Empresa
            </button>
          )}
          {showCampos && (
            <FormEstabelecimento
              estabelecimentoId={selectedEstabelecimentoId}
              isEditing={isEditing}
              onCancel={this.handleCancel}
            />
          )}
          <div className="row my-2 mx-0 px-0">
            <div className="col-lg-6 px-0">
              <div className="input-group">
                <input
                  type="text"
                  className="form-control"
                  name="search"
                  placeholder="Pesquisar"
                  value={searchQuery}
                  onChange={this.handleSearchChange}
                />
                <button className="btn btn-vk">Pesquisar</button>
              </div>
            </div>
          </div>
          <div className="table-responsive" style={{ marginTop: 20 }}>
            <table className="table table-bordered table-striped">
              <thead>
                <tr>
                  <th>Ações</th>
                  <th>Nome</th>
                  <th>Email</th>
                  <th>Telefone</th>
                </tr>
              </thead>
              <tbody>
                {isLoading ? (
                  <tr>
                    <td colSpan="4">
                      <div className="loader" id="loader">
                        <span></span>
                        <span></span>
                        <span></span>
                      </div>
                    </td>
                  </tr>
                ) : (
                  filteredEstabelecimentos.map((estabelecimento) => (
                    <tr key={estabelecimento.id_estabelecimento}>
                      <td>
                        <Pencil
                          className="cursor-pointer"
                          color="#FF5722"
                          onClick={() =>
                            this.toggleForm(
                              true,
                              estabelecimento.id_estabelecimento
                            )
                          }
                        />
                        <Trash3
                          color="#FF5722"
                          onClick={() => this.handleDelete(estabelecimento.id)}
                          className="cursor-pointer"
                        />
                      </td>
                      <td>{estabelecimento.razao_social}</td>
                      <td>{estabelecimento.email}</td>
                      <td>{estabelecimento.telefone}</td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>
          </div>
        </div>
        <Toaster />
      </section>
    );
  }
}

export default Empresas;
