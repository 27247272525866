import React, { Component } from "react";
import PropTypes from "prop-types";
import * as PrestAPI from "../../../service/api/PrestAPI";

class NewCategoriaComplement extends Component {
  static propTypes = {
    idEstabelecimento: PropTypes.number.isRequired,
    idProduto: PropTypes.number.isRequired,
    ocultaCategoria: PropTypes.bool.isRequired,
    getCatComplementos: PropTypes.func,
    ocultaCategoriaComponent: PropTypes.func.isRequired,
  };

  handleSubmit = (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);

    let status_categoria = formData.get("status_categoria") ? 1 : 0;
    let substitui_valor = formData.get("substitui_valor") ? 1 : 0;

    let data = {
      nome_categoria: formData.get("nome_categoria"),
      qtde_min: parseInt(formData.get("qtde_min"), 10) || 0,
      qtde_maxima: parseInt(formData.get("qtde_maxima"), 10) || 1,
      descricao_categoria: formData.get("descricao_categoria") || "",
      status_categoria,
      substitui_valor: formData.get("substitui_valor") ? true : false,
      modo_multiplo: formData.get("qtde_maxima") > 1 ? 1 : 0,
      uso_obrigatorio: formData.get("qtde_min") >= 1 ? 1 : 0,
      id_estabelecimento: this.props.idEstabelecimento,
      id_produto: this.props.idProduto,
    };

    if (data.nome_categoria) {
      this.saveCategoriaComplemento(data);
      this.props.ocultaCategoriaComponent();
    } else {
      alert("Informe os dados necessários");
    }
  };

  saveCategoriaComplemento(complemento) {
    PrestAPI.add("ProdutoComplemento", complemento)
      .then((response) => {
        if (!response.error) {
          if (this.props.getCatComplementos) {
            this.props.getCatComplementos(response);
            this.setState({ isShow: false });
          }
        } else {
          alert(response.error);
        }
      })
      .catch((error) => {
        alert(error);
      });
  }

  render() {
    const { ocultaCategoria } = this.props;

    if (ocultaCategoria) return null;

    return (
      <div className="cat-complement-geral">
        <br />
        <form onSubmit={this.handleSubmit}>
          <div className="form-group col-sm-4">
            <label>Nome grupo</label>
            <input
              type="text"
              name="nome_categoria"
              className="form-control input-sm"
              required
            />
          </div>
          <div className="form-group col-sm-3">
            <label>Quantidade mínima</label>
            <input
              type="number"
              name="qtde_min"
              min="0"
              className="form-control input-sm"
              required
            />
          </div>
          <div className="form-group col-sm-3">
            <label>Quantidade máxima</label>
            <input
              type="number"
              name="qtde_maxima"
              className="form-control input-sm"
              required
            />
          </div>
          <div className="form-group col-sm-2">
            <div>
              <label htmlFor="status_categoria">Status:</label>
            </div>
            <input
              type="checkbox"
              name="status_categoria"
              defaultChecked={true}
              style={{
                width: "30px",
                height: "30px",
                marginTop: "1px",
              }}
            />
          </div>
          <div className="form-group col-sm-7">
            <label>Descrição</label>
            <input
              type="text"
              name="descricao_categoria"
              className="form-control input-sm"
            />
          </div>
          <div className="form-group col-sm-2">
            <div>
              <label>Substitui valor:</label>
            </div>
            <input
              type="checkbox"
              name="substitui_valor"
              defaultChecked={false}
              style={{
                width: "30px",
                height: "30px",
                marginTop: "1px",
              }}
            />
          </div>
          <div className="form-group col-sm-3" style={{ paddingTop: "4px" }}>
            <button className="form-control input-sm btn-vk" type="submit">
              Salvar
            </button>
          </div>
        </form>
      </div>
    );
  }
}

export default NewCategoriaComplement;
