import React, { Component } from "react";
import PropTypes from "prop-types";
import ReactLoading from "react-loading";
import "./Login.css";
import { toast } from "react-hot-toast";

var CryptoJS = require("crypto-js");

class Login extends Component {
  static propTypes = {
    login: PropTypes.func,
    imagem: PropTypes.string,
  };

  state = {
    loading: true,
    username: "",
    password: "",
  };

  handleSubmit = () => {
    let username = this.state.username;
    let password = this.state.password;

    if (username !== "" && password !== "") {
      let data = {
        username: this.state.username,
        password: this.state.password,
      };

      if (this.props.login) {
        this.props.login(data);
      }
    } else {
      toast.error("Há campos vazios no formulário", {
        id: "erroLogin",
        duration: 1000,
      });
    }
  };

  async componentDidMount() {
    if (this.state.loading) {
      let loggedDados = localStorage.getItem("Estab");

      if (loggedDados !== null) {
        let decryptDados = CryptoJS.AES.decrypt(
          loggedDados,
          process.env.REACT_APP_ENCRYPT_PASS
        );
        let decryptedDados = JSON.parse(
          decryptDados.toString(CryptoJS.enc.Utf8)
          
        );

        const filtroDados = {
          username: decryptedDados.uid,
          password: decryptedDados.ticket,
        };

        await this.props.login(filtroDados);
      } else {
        setTimeout(() => {
          this.setState({ loading: false });
        }, 300);
      }
    }
  }

  render() {
    const { loading } = this.state;
    const { imagem } = this.props;
    return (
      <>
        {!loading ? (
          <div
            className="container-fluid mx-auto p-5 rounded-2 mt-5 w-100 bg-white"
            style={{ maxWidth: "800px" }}
          >
            <div className="row justify-content-center py-5">
              <div className="col-sm-8">
                <img
                  className="col-sm-12 imagem-login"
                  src={imagem}
                  alt="..."
                />
                <label className="form-label">Usuário:</label>
                <input
                  name="cnpj"
                  type="text"
                  className="form-control form-control-lg"
                  placeholder="Digite o usuário"
                  required
                  style={{ marginBottom: "16px" }}
                  onChange={(e) => this.setState({ username: e.target.value })}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      this.handleSubmit();
                    }
                  }}
                />
                <label className="form-label">Senha:</label>
                <input
                  name="senha"
                  type="password"
                  className="form-control form-control-lg mt-2"
                  placeholder="Digite sua senha"
                  required
                  onChange={(e) => this.setState({ password: e.target.value })}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      this.handleSubmit();
                    }
                  }}
                />
                <button
                  className="btn btn-lg w-100 btn-vk mt-3"
                  onClick={() => this.handleSubmit()}
                >
                  Entrar
                </button>
              </div>
            </div>
          </div>
        ) : (
          <div className="loaderApp">
            <ReactLoading
              type="spinningBubbles"
              color="#ff5722"
              height={100}
              width={100}
            />
          </div>
        )}
      </>
    );
  }
}

export default Login;
