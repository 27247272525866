import React, { Component } from "react";
import Perfil from "../Perfil/Perfil";
import Empresas from "../Empresas/Empresas";

class Config extends Component {
  state = {
    activeTab: "perfil",
  };

  setActiveTab = (tab) => {
    this.setState({ activeTab: tab });
  };

  renderTabContent = () => {
    const { activeTab } = this.state;
    switch (activeTab) {
      case "perfil":
        return <Perfil />;
      case "empresas":
        return <Empresas />;
      case "planos":
        return <div>Planos Content</div>;
      case "ajuda":
        return <div>Ajuda Content</div>;
      default:
        return null;
    }
  };

  renderTab = (tab, label) => {
    const { activeTab } = this.state;
    return (
      <li className="nav-item" role="presentation">
        <button
          className={`nav-link cursor-pointer ${
            activeTab === tab ? "active" : "text-dark"
          }`}
          onClick={() => this.setActiveTab(tab)}
        >
          {label}
        </button>
      </li>
    );
  };

  render() {
    return (
      <div className="container-fluid container">
        <div className="my-4">
          <h2 className="text-center" style={{ color: "#FF5722" }}>
            Configurações
          </h2>
          <ul className="nav nav-tabs">
            {this.renderTab("perfil", "Perfil")}
            {this.renderTab("empresas", "Empresas")}
            {this.renderTab("planos", "Planos")}
            {this.renderTab("ajuda", "Ajuda")}
          </ul>
          <div className="tab-content mt-3">{this.renderTabContent()}</div>
        </div>
      </div>
    );
  }
}

export default Config;
