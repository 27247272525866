import React, { Component } from "react";
import "./Relatorios.css";
import PropTypes from "prop-types";

class PedidoRelatorio extends Component {
  static propTypes = {
    pedido: PropTypes.object.isRequired,
    showModal: PropTypes.func,
    hideModal: PropTypes.func,
  };

  getStatusInfo(statusPedido) {
    const statusInfo = {
      CONFIRMADO: { color: "DeepSkyBlue", label: "Confirmado" },
      ENVIADO: { color: "gray", label: "Enviado" },
      ENTREGUE: { color: "green", label: "Entregue" },
      CANCELADO: { color: "#8c2626", label: "Cancelado" },
      PENDENTE: { color: "orange", label: "Pendente" },
    };
    return statusInfo[statusPedido] || statusInfo.PENDENTE;
  }
  getStatusColor(statusPedido) {
    const statusInfo = {
      CONFIRMADO: { colors: ["DeepSkyBlue", "#e3e3e3"], label: "Confirmado" },
      ENVIADO: { colors: ["gray", "#e3e3e3"], label: "Enviado" },
      ENTREGUE: { colors: ["green", "darkgreen"], label: "Entregue" },
      CANCELADO: { colors: ["#ff0000", "#8c2626"], label: "Cancelado" },
      PENDENTE: { colors: ["orange", "darkorange"], label: "Pendente" },
    };
    return statusInfo[statusPedido] || statusInfo.PENDENTE;
  }

  render() {
    const { pedido, showModal } = this.props;
    const { colors: borderColors, label: statusLabel } = this.getStatusColor(
      pedido.status_pedido
    );

    return (
      <div
        className={`card clickable panelPedido mb-2 px-3 py-2`}
        style={{
          backgroundColor: `${
            pedido.status_pedido === "PENDENTE"
              ? "#fcee9f"
              : pedido.status_pedido === "ENTREGUE"
              ? "#abfc8b"
              : pedido.status_pedido === "CANCELADO"
              ? "#fc9797"
              : ""
          }`,
        }}
        onClick={showModal}
      >
        <div className="w-100">
          <div className="row align-items-center">
            <div className="col-2 text-center">
              <b>{pedido.id_pedido}</b>
            </div>

            <div
              className="col-2 text-center"
              style={{
                borderLeft: `solid 1px ${borderColors[1]}`,
              }}
            >
              {pedido.nome_cliente.length > 10
                ? `${pedido.nome_cliente.substring(0, 10)}...`
                : pedido.nome_cliente}
            </div>

            <div
              className="col-2 text-center"
              style={{
                borderLeft: `solid 1px ${borderColors[1]}`,
              }}
            >
              <div
                className="col-12 rounded-2"
                style={{ border: `1px solid ${borderColors[0]}` }}
              >
                {statusLabel}
              </div>
            </div>

            <div
              className="col-2 text-center"
              style={{
                borderLeft: `solid 1px ${borderColors[1]}`,
              }}
            >
              {new Date(pedido.data_hora).toLocaleDateString("pt-BR")}
            </div>

            <div
              className="col-2 text-center text-uppercase"
              style={{
                borderLeft: `solid 1px ${borderColors[1]}`,
              }}
            >
              {pedido.forma_pagamento}
            </div>

            <div
              className="col-2 text-center"
              style={{
                borderLeft: `solid 1px ${borderColors[1]}`,
              }}
            >
              <b>
                {new Intl.NumberFormat("pt-BR", {
                  style: "currency",
                  currency: "BRL",
                }).format(pedido.vr_pedido)}
              </b>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default PedidoRelatorio;
