import React, { createContext, Component } from "react";

const EstabelecimentoContext = createContext();

export class EstabelecimentoProvider extends Component {
  state = {
    estabelecimento: null,
  };

  setEstabelecimento = (estabelecimento) => {
    this.setState({ estabelecimento });
  };

  render() {
    return (
      <EstabelecimentoContext.Provider
        value={{
          estabelecimento: this.state.estabelecimento,
          setEstabelecimento: this.setEstabelecimento,
        }}
      >
        {this.props.children}
      </EstabelecimentoContext.Provider>
    );
  }
}

export const EstabelecimentoConsumer = EstabelecimentoContext.Consumer;

export default EstabelecimentoContext;
