import React, { Component } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

class ProdutoGrupoForm extends Component {
  static propTypes = {
    grupo: PropTypes.object.isRequired,
    saveGrupo: PropTypes.func.isRequired,
  };

  handleSubmit = (event) => {
    event.preventDefault();
    const form = event.target;

    const formData = new FormData(form);
    const data = {};

    for (let [key, value] of formData.entries()) {
      data[key] = value;
    }

    data.id_grupo = Number.parseInt(data.id_grupo, 10);
    data.nr_sequencia = Number(data.nr_sequencia);

    data.existem_produtos = form.elements.existem_produtos.checked;

    const { id_estabelecimento } = this.props.grupo;
    data.id_estabelecimento = String(id_estabelecimento);
    if (this.props.saveGrupo) {
      this.props.saveGrupo(data.id_grupo, data, data.id_estabelecimento);
    }
  };

  render() {
    const { grupo } = this.props;

    return (
      <div className="container-fluid container">
        <div className="row justify-content-center">
          <div className="col-md-10">
            <div className="row">
              <div className="col-md-8">
                <h3>Produto Grupo - Cadastro</h3>
              </div>
              <div className="col-md-4">
                <Link to="/produto_grupo-add" className="btn w-100 btn-vk">
                  Novo Grupo
                </Link>
              </div>
            </div>

            <div className="col-12">
              <form onSubmit={this.handleSubmit}>
                <div className="col-sm-9">
                  <div className="row">
                    <div className="row">
                      <div className="col-sm-2">
                        <label className="form-check-label">Status:</label>
                        <div className="form-check">
                          <input
                            type="checkbox"
                            name="existem_produtos"
                            defaultChecked={grupo.existem_produtos}
                            className="form-check-input"
                            style={{ width: "30px", height: "30px" }}
                          />
                        </div>
                      </div>
                      <div className="col-sm-4">
                        <label className="form-label">Id.Grupo:</label>
                        <input
                          type="text"
                          name="id_grupo"
                          defaultValue={grupo.id_grupo}
                          className="form-control input-sm"
                          placeholder=""
                          readOnly
                        />
                      </div>
                      <div className="col-sm-3">
                        <label className="form-label">Sequência:</label>
                        <input
                          type="number"
                          name="nr_sequencia"
                          defaultValue={grupo.nr_sequencia || 99}
                          className="form-control input-sm"
                          placeholder=""
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-9">
                        <label className="form-label">Descrição:</label>
                        <input
                          type="text"
                          name="descricao"
                          defaultValue={grupo.descricao}
                          className="form-control input-sm"
                          placeholder=""
                          required
                        />
                      </div>
                    </div>
                    <div className="row mt-4">
                      <div className="col-sm-3">
                        <Link
                          to="/produto"
                          className="btn btn-outline-secondary btn-lg w-100"
                        >
                          Voltar
                        </Link>
                      </div>
                      <div className="col-sm-9">
                        <button className="btn btn-vk btn-lg w-100">
                          Salvar
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ProdutoGrupoForm;
