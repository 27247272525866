import React from "react";
import PropTypes from "prop-types";

class InputField extends React.Component {
  render() {
    const {
      className,
      name,
      label,
      errorMessage,
      value,
      onChange,
      ...inputProps
    } = this.props;

    return (
      <div className={`${className}  mb-2`}>
        <label htmlFor={name} className="form-label">
          {label}
        </label>
        <input
          {...inputProps}
          className={`form-control ${errorMessage ? "is-invalid" : ""}`}
          name={name}
          id={name}
          value={value}
          onChange={onChange}
        />
        {errorMessage && <div className="invalid-feedback">{errorMessage}</div>}
      </div>
    );
  }
}

InputField.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  errorMessage: PropTypes.string,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

InputField.defaultProps = {
  className: "",
  errorMessage: "",
};

export default InputField;
