import { PropTypes } from "prop-types";
import React, { Component } from "react";
import "./Relatorios.css";
import { formatCurrencyBRL } from "../../utils/formatters";
class ModalRelatorio extends Component {
  static propTypes = {
    pedido: PropTypes.object.isRequired,
    subtotal: PropTypes.number.isRequired,
    itens: PropTypes.array,
  };

  state = {
    loading: false,
    documentoHide: "",
  };

  renderSwitch(statusPedido) {
    switch (statusPedido) {
      case "CONFIRMADO":
        return (
          <h4
            style={{
              border: "1px solid DeepSkyBlue",
              borderRadius: "5px",
              textAlign: "center",
              margin: "20px, 0px, 10px",
            }}
          >
            Confirmado
          </h4>
        );
      case "ENVIADO":
        return (
          <h4
            style={{
              border: "1px solid gray",
              borderRadius: "5px",
              textAlign: "center",
              margin: "20px, 0px, 10px",
            }}
          >
            Enviado
          </h4>
        );
      case "ENTREGUE":
        return (
          <h4
            style={{
              border: "1px solid green",
              borderRadius: "5px",
              textAlign: "center",
              margin: "20px, 0px, 10px",
            }}
          >
            Entregue
          </h4>
        );
      case "CANCELADO":
        return (
          <h4
            style={{
              border: "1px solid red",
              borderRadius: "5px",
              textAlign: "center",
              margin: "20px, 0px, 10px",
            }}
          >
            Cancelado
          </h4>
        );
      default:
        return (
          <h4
            style={{
              border: "1px solid orange",
              borderRadius: "5px",
              textAlign: "center",
              margin: "20px, 0px, 10px",
            }}
          >
            Pendente
          </h4>
        );
    }
  }

  hideDocument = () => {
    let documento = this.props.pedido.documento;

    if (documento !== null && documento !== undefined && documento !== "") {
      if (documento.length === 14) {
        documento = documento.substr(0, 5);
        documento = documento += "**.***-**";
        this.setState({ documentoHide: documento });
      } else {
        documento = documento.substr(0, 4);
        documento = documento += "**.***/****-**";
        this.setState({ documentoHide: documento });
      }
    } else {
      this.setState({ documentoHide: "" });
    }
  };

  loader = () => {
    this.setState({ loading: true });
    setTimeout(() => {
      this.setState({ loading: false });
    }, 1000);
  };

  componentDidMount() {
    this.loader();
    setTimeout(() => {
      this.hideDocument();
    }, 500);
  }

  render() {
    const { pedido, subtotal, itens } = this.props;
    const { loading, documentoHide } = this.state;
    return (
      <>
        {!loading ? (
          <div className="row">
            <div
              className="card col-sm-8 col-12 w-100"
              style={{
                padding: "30px 80px 30px 80px",
              }}
            >
              <div className="row">
                <div
                  className="col-sm-4"
                  style={{
                    WebkitUserSelect: "none",
                    MozUserSelect: "none",
                    MsUserSelect: "none",
                    userSelect: "none",
                  }}
                >
                  {this.renderSwitch(pedido.status_pedido)}
                </div>
                <div className="col-4 text-center">
                  <h3>
                    <b>{`Pedido ${pedido.id_pedido}`}</b>
                  </h3>
                  {pedido.tipo_pedido === "RETIRADA" ? (
                    <h4 style={{ color: "red" }}>Pedido para retirada</h4>
                  ) : null}
                </div>
                <div className="col-4">
                  <h3 style={{ fontSize: "15px" }}>
                    {new Date(pedido.data_hora).toLocaleString("pt-BR", {
                      timeZone: "GMT",
                    })}
                  </h3>
                </div>
              </div>
              {pedido.id_pedido && (
                <div className="row">
                  <div className="col-sm-8 text-left">
                    <h5>
                      <b>Cliente:</b>
                      <div style={{ fontSize: "16px" }}>
                        {` ${pedido.nome_cliente}`}
                      </div>
                    </h5>
                  </div>
                  {pedido.telefone && (
                    <div className="col-sm-4 text-left">
                      <b>Telefone:</b>
                      <div style={{ fontSize: "15px" }}>{pedido.telefone}</div>
                    </div>
                  )}
                  <div className="w-100"></div>
                  <div
                    className={pedido.documento ? "col-sm-8" : "col-sm-12"}
                    style={{ textAlign: "left" }}
                  >
                    <h5 style={{ marginTop: "10px", marginBottom: "0px" }}>
                      <b>Endereço:</b>
                      <div style={{ fontSize: "16px" }}>
                        {`${pedido.entrega_logradouro}, ${pedido.entrega_numero} `}
                      </div>
                      <div style={{ fontSize: "16px" }}>
                        {`${pedido.entrega_bairro} - ${pedido.entrega_municipio}, ${pedido.entrega_uf} `}
                      </div>
                      <div style={{ fontSize: "16px" }}>
                        {pedido.entrega_complemento !== ""
                          ? pedido.entrega_complemento
                          : null}
                      </div>
                    </h5>
                  </div>
                  {pedido.documento && (
                    <div className="col-sm-4 text-left">
                      <h5 className="mt-2">
                        <b>Documento:</b>
                        <div style={{ fontSize: "16px" }}>{documentoHide}</div>
                      </h5>
                    </div>
                  )}

                  <div className="col-sm-12">
                    <hr
                      style={{ borderTop: "solid 1.5px", color: "lightgray" }}
                    />
                  </div>
                  <div className="container-fluid col-sm-12">
                    {itens.map((item) => (
                      <div className="card" key={item.num_item}>
                        <div className="card-body">
                          <div className="row">
                            <div
                              className="col"
                              style={{ fontSize: "15px" }}
                            >{`${item.quantidade}x ${item.nome_produto}`}</div>
                            <div className="col text-end">
                              {formatCurrencyBRL(item.vr_unitario)}
                            </div>
                            {item.subItens &&
                              item.subItens.map(
                                (complemento) =>
                                  complemento && (
                                    <div
                                      key={complemento.id_complemento}
                                      className="col-12 row"
                                    >
                                      <div
                                        className="col text-body-secondary ps-4"
                                        style={{ fontSize: "12px" }}
                                      >
                                        {` ${complemento.quantidade}x ${complemento.complemento}`}
                                      </div>
                                      <div
                                        className="col text-body-secondary justify-content-end row"
                                        style={{
                                          fontSize: "12px",
                                        }}
                                      >
                                        {formatCurrencyBRL(
                                          complemento.vr_adicional
                                        )}
                                      </div>
                                    </div>
                                  )
                              )}

                            <div
                              className="col-12 text-end"
                              style={{
                                fontWeight: "bold",
                                fontSize: "16px",
                              }}
                            >
                              {item.vr_total_sub_iten
                                ? formatCurrencyBRL(
                                    item.vr_unitario + item.vr_total_sub_itens
                                  )
                                : formatCurrencyBRL(item.vr_total)}
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}

                    <div className="row">
                      <div className="col-md-6 ms-auto">
                        <div className="row justity-content-between">
                          <div className="col" style={{ fontSize: "16px" }}>
                            <b>Subtotal:</b>
                          </div>
                          <div
                            className="col text-end"
                            style={{ fontSize: "16px" }}
                          >
                            <b>{formatCurrencyBRL(subtotal)}</b>
                          </div>
                        </div>
                        <div className="row justity-content-between">
                          <div className="col">
                            <b>Taxa:</b>
                          </div>
                          <div className="col text-end">
                            <b>{formatCurrencyBRL(pedido.entrega_taxa)}</b>
                          </div>
                        </div>
                        <div className="row justity-content-between">
                          <div className="col" style={{ fontSize: "16px" }}>
                            <b>Total:</b>
                          </div>
                          <div
                            className="col text-end"
                            style={{ fontSize: "16px" }}
                          >
                            <b>{formatCurrencyBRL(pedido.vr_pedido)}</b>
                          </div>
                        </div>
                        {pedido.vr_troco !== 0 && (
                          <div className="row justity-content-between">
                            <div
                              className="col"
                              style={{
                                fontSize: "16px",
                              }}
                            >
                              <b>Troco para:</b>
                            </div>
                            <div
                              className="col text-end"
                              style={{ fontSize: "16px" }}
                            >
                              <b>{formatCurrencyBRL(pedido.vr_troco)}</b>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>

                    <div
                      className="card w-100"
                      style={{
                        border: "none",
                      }}
                    >
                      <div className="col-12">
                        <b>Forma de Pagamento:</b>
                      </div>
                      <div className="col-12 text-uppercase">
                        {pedido.forma_pagamento}
                      </div>
                      {pedido.entrega_observacao ? (
                        <>
                          <div
                            className="col-sm-12 text-left"
                            style={{ marginTop: "36px" }}
                          >
                            <h5
                              style={{ marginTop: "10px", marginBottom: "0px" }}
                            >
                              <b>Observações:</b>
                            </h5>
                          </div>
                          <div className="col-sm-12 text-left">
                            <div style={{ fontSize: "16px" }}>
                              {pedido.entrega_observacao}
                            </div>
                          </div>
                        </>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-sm-12">
                    <hr
                      style={{ borderTop: "solid 1.5px", color: "lightgray" }}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        ) : (
          <div className="loaderRela" id="loader">
            <span></span>
            <span></span>
            <span></span>
          </div>
        )}
      </>
    );
  }
}

export default ModalRelatorio;
