const api = process.env.REACT_APP_API;
const apiGatepeeker = process.env.REACT_APP_API_GATEKEEPER;

// Generate a unique token for storing your bookshelf data on the backend server.
let token = localStorage.token;
if (!token) token = localStorage.token = Math.random().toString(36).substr(-8);

let headers = {
  Accept: "application/json",
  Authorization: "Bearer " + token,
  "Content-Type": "application/json",
};

export const getToken = (table, data) =>
  fetch(`${apiGatepeeker}/${table}`, {
    body: JSON.stringify(data),
    method: "POST",
    headers: { "Content-Type": "application/json" },
  })
    .then((res) => res.json())
    .then((data) => {
      if (data.value) {
        localStorage.token = token = data.token;
        headers = {
          Accept: "application/json",
          Authorization: "Bearer " + data.token,
          "Content-Type": "application/json",
        };
      }
      return data;
    });

export async function get(table, where) {
  const queryString = where ? `?${where}` : "";
  const response = await fetch(`${api}/${table}${queryString}`, {
    headers,
  });
  return await response.json();
}

export const update = (table, where, content) =>
  fetch(`${api}/${table}${where}`, {
    method: "PUT",
    headers: headers,
    body: JSON.stringify(content),
  }).then((res) => (res.bodyUsed ? res.json() : res));

export const add = (table, value) => {
  const isFormData = value instanceof FormData;
  return fetch(`${api}/${table}`, {
    method: "POST",
    headers: isFormData
      ? { Authorization: headers.Authorization }
      : { ...headers, "Content-Type": "application/json" },
    body: isFormData ? value : JSON.stringify(value),
  }).then((res) => (res.bodyUsed ? res.json() : res));
};
// .then((res) => res.json())
// .then((data) => data);

export const del = (table, where) =>
  fetch(`${api}/${table}${where}`, {
    method: "DELETE",
    headers: headers,
  });
// .then(res => res.json())
//     .then(data => data)

export const delTaxa = (table, value) =>
  fetch(`${api}/${table}`, {
    method: "DELETE",
    headers: headers,
    body: JSON.stringify(value),
  });

export const getCep = async (cep) => {
  const response = await fetch(`https://viacep.com.br/ws/${cep}/json/`);

  return response;
};
