import React, { Component } from "react";
import PropTypes from "prop-types";
import * as PrestAPI from "../../../service/api/PrestAPI";
import { toast } from "react-hot-toast";
import "./styles.css";

class ModalCategoriaComplement extends Component {
  static propTypes = {
    categoria: PropTypes.object.isRequired,
    deleteCatComplemento: PropTypes.func,
    getCatComplementos: PropTypes.func,
    hideModal: PropTypes.func,
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const { categoria } = this.props;
    const formData = new FormData(e.target);

    const data = {
      nome_categoria: formData.get("nome_categoria"),
      qtde_min: parseInt(formData.get("qtde_min"), 10) || 0,
      qtde_maxima: parseInt(formData.get("qtde_maxima"), 10) || 1,
      sequencia: parseInt(formData.get("sequencia"), 10) || 0,
      status_categoria: formData.get("status_categoria") ? 1 : 0,
      substitui_valor: formData.get("substitui_valor") ? true : false,
      descricao_categoria: formData.get("descricao_categoria") || "",
      modo_multiplo: formData.get("qtde_maxima") > 1 ? 1 : 0,
      uso_obrigatorio: formData.get("qtde_min") >= 1 ? 1 : 0,
      id_estabelecimento: parseInt(categoria.id_estabelecimento, 10),
      id_produto: parseInt(categoria.id_produto, 10),
      id_categoria_complemento: parseInt(
        categoria.id_categoria_complemento,
        10
      ),
    };

    this.atualizaCat(data);
    this.props.hideModal();
  };

  atualizaCat = (categoria) => {
    PrestAPI.update(
      "ProdutoComplemento",
      `?idCategoria=${categoria.id_categoria_complemento}`,
      categoria
    ).then((res) => {
      if (res.status === 200) {
        this.props.getCatComplementos(categoria);
        this.props.hideModal();
      } else {
        console.error(res.error);
      }
    });
  };

  state = {};

  render() {
    const { categoria } = this.props;
    console.log("vlaor: ", categoria.substitui_valor);

    return (
      <div className="container-fluid p-2">
        <form onSubmit={this.handleSubmit} className="row g-3">
          <div className="row fs-4">
            <b>Editar grupo</b>
          </div>
          <div className="col-sm-5">
            <label className="form-label">Nome grupo</label>
            <input
              type="text"
              name="nome_categoria"
              defaultValue={categoria.nome_categoria}
              className="form-control input-sm"
              placeholder=""
            />
          </div>
          <div className="col-sm-2">
            <label className="form-label">Qtde Min</label>
            <input
              type="number"
              name="qtde_min"
              defaultValue={categoria.qtde_min}
              min="0"
              className="form-control input-sm"
              placeholder=""
              style={{ textAlign: "center" }}
            />
          </div>
          <div className="col-sm-2">
            <label className="form-label">Qtde Max</label>
            <input
              type="number"
              name="qtde_maxima"
              defaultValue={categoria.qtde_maxima}
              className="form-control input-sm"
              placeholder=""
              style={{ textAlign: "center" }}
            />
          </div>
          <div className="col-sm-1">
            <label className="form-label">Sequência</label>
            <input
              type="number"
              name="sequencia"
              defaultValue={categoria.sequencia}
              className="form-control input-sm"
              placeholder=""
              style={{ width: "50px", textAlign: "center" }}
            />
          </div>
          <div className="col-sm-2">
            <label className="form-label" htmlFor="status">
              Status:
            </label>

            <input
              type="checkbox"
              name="status_categoria"
              defaultChecked={categoria.status_categoria === 1}
              id="status_categoria"
              style={{
                width: "30px",
                height: "30px",
                marginTop: "1px",
              }}
            />
          </div>
          <div className="form-group col-sm-2">
            <label>Qtde Min</label>
            <input
              type="number"
              name="qtde_min"
              defaultValue={categoria.qtde_min}
              min="0"
              className="form-control input-sm"
              placeholder=""
              style={{ textAlign: "center" }}
            />
          </div>
          <div className="form-group col-sm-2">
            <label>Qtde Max</label>
            <input
              type="number"
              name="qtde_maxima"
              defaultValue={categoria.qtde_maxima}
              className="form-control input-sm"
              placeholder=""
              style={{ textAlign: "center" }}
            />
          </div>
          <div className="form-group col-sm-1">
            <label>Sequência</label>
            <input
              type="number"
              name="sequencia"
              defaultValue={categoria.sequencia}
              className="form-control input-sm"
              placeholder=""
              style={{ width: "50px", textAlign: "center" }}
            />
          </div>

          <div className="col-sm-2">
            <label htmlFor="substitui_valor" className="form-label">
              Subistitui valor:
            </label>

            <input
              type="checkbox"
              name="substitui_valor"
              defaultChecked={categoria.substitui_valor}
              id="substitui_valor"
              style={{
                width: "30px",
                height: "30px",
                marginTop: "1px",
              }}
            />
          </div>
          <div className="col-sm-5">
            <label>Descrição</label>
            <input
              type="text"
              name="descricao_categoria"
              defaultValue={categoria.descricao_categoria}
              className="form-control input-sm"
              placeholder=""
            />
          </div>
          <div className="col-sm-2">
            <label htmlFor="status" className="form-label">
              Status:
            </label>

            <input
              type="checkbox"
              name="status_categoria"
              defaultChecked={categoria.status_categoria === 1}
              id="status_categoria"
              style={{
                width: "30px",
                height: "30px",
                marginTop: "1px",
              }}
            />
          </div>
          {/* <div className="col-sm-5">
          <input
            type="checkbox"
            name="uso_obrigatorio"
            defaultChecked={item.uso_obrigatorio}
            defaultValue={item.uso_obrigatorio === "on" ? "1" : "0"}
            style={{ alignItems: "center" }}
          />{" "}
          Uso obrigatório{" "}
          <input
            type="checkbox"
            name="modo_multiplo"
            defaultChecked={item.modo_multiplo}
            defaultValue={item.modo_multiplo === "on" ? "1" : "0"}
            style={{ alignItems: "center" }}
          />{" "}
          Multiplo {""}
          <input
            type="checkbox"
            name="modo_cobranca"
            defaultChecked={item.modo_cobranca}
            defaultValue={item.modo_cobranca === "on" ? "1" : "0"}
            style={{ alignItems: "center" }}
          />{" "}
          Cobrar pelo mais caro
        </div> */}

          {/* <div className="col-sm-1">
          <div className="form-group col-sm-1" hidden={true}>
            <label>Estabelecimento</label>
            <input
              type="text"
              name="id_estabelecimento"
              defaultValue={item.estabelecimento}
            />
          </div>
          <div className="form-group col-sm-1" hidden={true}>
            <label>Produto</label>
            <input
              type="text"
              name="id_produto"
              defaultValue={item.id_produto}
            />
          </div>
          <div className="form-group col-sm-1" hidden={true}>
            <label>ID</label>
            <input
              type="text"
              name="id_categoria_complemento"
              defaultValue={item.id_categoria_complemento}
            />
          </div>
        </div> */}
          <div className="form-group col-sm-2">
            <label></label>
            <button type="submit" className="input-sm btn-vk form-control">
              Salvar
            </button>
          </div>
          <div className="form-group col-sm-2">
            <label></label>
            <button
              type="button"
              onClick={() => {
                this.props.deleteCatComplemento(
                  categoria.id_estabelecimento,
                  categoria.id_produto,
                  categoria.id_categoria_complemento
                );
                this.props.hideModal();
              }}
              className="input-sm btnEx form-control"
            >
              Excluir
            </button>
          </div>
        </form>
      </div>
    );
  }
}

export default ModalCategoriaComplement;
