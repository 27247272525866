import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import "./index.css";
import App from "./App";
import registerServiceWorker from "./registerServiceWorker";

import "bootstrap/dist/css/bootstrap.min.css";

import { EstabelecimentoProvider } from "./context/EstabelecimentoContext.js";

ReactDOM.render(
  <EstabelecimentoProvider>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </EstabelecimentoProvider>,
  document.getElementById("root")
);

registerServiceWorker();
